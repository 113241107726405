import { ResponseErrorReason } from "@/services/ResponseErrorReason";
import { useAuthStore } from "@/stores/auth";
import { useUserProfileStore } from "@/stores/userProfile";
import { Config } from "@/utils/Config";
import { Consoler } from "@/utils/Consoler";
import { EventBus } from "@/utils/EventBus";
import {
  createRouter,
  createWebHistory,
  type RouteComponent,
  type RouteLocationRaw,
  type RouteRecordRaw,
  RouterView,
} from "vue-router";
import { RouteNames } from "./routeNames";

const ConfirmUserPage = (): Promise<RouteComponent> =>
  import("@/views/ConfirmUserPage.vue");
const ConfirmEmailPage = (): Promise<RouteComponent> =>
  import("@/views/ConfirmEmailPage.vue");
const ConfirmPasswordPage = (): Promise<RouteComponent> =>
  import("@/views/ConfirmPasswordPage.vue");

const NotFoundAndErrorPage = (): Promise<RouteComponent> =>
  import("@/views/NotFoundAndErrorPage.vue");

const ProfilePage = (): Promise<RouteComponent> =>
  import("@/views/ProfilePage.vue");
const ProfileSettingsPage = (): Promise<RouteComponent> =>
  import("@/views/ProfileSettingsPage.vue");
const ProfileSchedulingPage = (): Promise<RouteComponent> =>
  import("@/views/ProfileSchedulingPage.vue");

const DashboardPage = (): Promise<RouteComponent> =>
  import("@/views/DashboardPage.vue");

const SitesPage = (): Promise<RouteComponent> =>
  import("@/views/SitesPage.vue");
const SiteDetailsPage = (): Promise<RouteComponent> =>
  import("@/views/SiteDetailsPage.vue");
const SiteIncidentPage = (): Promise<RouteComponent> =>
  import("@/views/SiteIncidentPage.vue");
const SiteVncPage = (): Promise<RouteComponent> =>
  import("@/views/SiteVncPage.vue");
const SiteReportPage = (): Promise<RouteComponent> =>
  import("@/views/SiteReportPage.vue");
const SiteLogsPage = (): Promise<RouteComponent> =>
  import("@/views/SiteLogsPage.vue");
const SitePerformanceTestingReportPage = (): Promise<RouteComponent> =>
  import("@/views/SitePerformanceTestingReportPage.vue");
const SiteSetupPage = (): Promise<RouteComponent> =>
  import("@/views/SiteSetupPage.vue");
const SiteSetupReviewPage = (): Promise<RouteComponent> =>
  import("@/views/SiteSetupReviewPage.vue");

const TeamsPage = (): Promise<RouteComponent> =>
  import("@/views/TeamsPage.vue");
const TeamDetailsPage = (): Promise<RouteComponent> =>
  import("@/views/TeamDetailsPage.vue");

const NewsDetailsPage = (): Promise<RouteComponent> =>
  import("@/views/NewsDetailsPage.vue");

const PrivacyPolicyPage = (): Promise<RouteComponent> =>
  import("@/views/PrivacyPolicyPage.vue");

const PublicSiteLogsPage = (): Promise<RouteComponent> =>
  import("@/views/public/PublicSiteLogsPage.vue");

// TODO: Activate once services pages should be shown
// const ServicesPage = (): Promise<RouteComponent> =>
//   import("@/views/ServicesPage.vue");
// const ServiceDetailsPage = (): Promise<RouteComponent> =>
//   import("@/views/ServiceDetailsPage.vue");

const routes: Array<RouteRecordRaw> = [
  {
    name: RouteNames.Dashboard,
    path: "/",
    component: DashboardPage,
  },
  {
    name: RouteNames.ConfirmUser,
    path: "/confirm-user",
    component: ConfirmUserPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: RouteNames.ConfirmEmail,
    path: "/confirm-user-email",
    component: ConfirmEmailPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: RouteNames.ConfirmPassword,
    path: "/confirm-password",
    component: ConfirmPasswordPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: RouteNames.ProfileRoot,
    path: "/profile",
    redirect: "/profile",
    component: RouterView,
    children: [
      {
        name: RouteNames.Profile,
        path: "",
        component: ProfilePage,
      },
      {
        name: RouteNames.ProfileScheduling,
        path: "scheduling",
        component: ProfileSchedulingPage,
      },
      {
        name: RouteNames.ProfileSettings,
        path: "settings",
        component: ProfileSettingsPage,
      },
    ],
  },
  {
    name: RouteNames.SitesRoot,
    path: "/sites",
    redirect: "/sites",
    component: RouterView,
    children: [
      {
        name: RouteNames.Sites,
        path: "",
        component: SitesPage,
      },
      {
        name: RouteNames.SiteSetupRoot,
        path: "/setup",
        redirect: "/setup",
        component: RouterView,
        children: [
          {
            name: RouteNames.SiteSetup,
            path: "",
            component: SiteSetupPage,
          },
          {
            name: RouteNames.SiteSetupReview,
            path: "review",
            component: SiteSetupReviewPage,
          },
        ],
      },
      {
        name: RouteNames.SiteRoot,
        path: ":siteId",
        redirect: ":siteId",
        component: RouterView,
        children: [
          {
            name: RouteNames.SiteDetails,
            path: "",
            component: SiteDetailsPage,
          },
          {
            name: RouteNames.SiteIncident,
            path: "incidents/:incidentId",
            component: SiteIncidentPage,
          },
          {
            name: RouteNames.SiteVnc,
            path: "vnc",
            component: SiteVncPage,
          },
          {
            name: RouteNames.SiteReport,
            path: "PerformanceReport",
            component: SiteReportPage,
          },
          {
            name: RouteNames.SiteLogs,
            path: "log",
            component: SiteLogsPage,
          },
          {
            name: RouteNames.SitePerformanceTestingReport,
            path: "performance-testing/:ptrId",
            component: SitePerformanceTestingReportPage,
          },
        ],
      },
    ],
  },
  {
    name: RouteNames.TeamsRoot,
    path: "/teams",
    redirect: "/teams",
    component: RouterView,
    children: [
      {
        name: RouteNames.Teams,
        path: "",
        component: TeamsPage,
      },
      {
        name: RouteNames.TeamDetails,
        path: ":teamId",
        component: TeamDetailsPage,
      },
    ],
  },
  {
    name: RouteNames.NewsDetails,
    path: "/news/:newsId",
    component: NewsDetailsPage,
  },
  // TODO: Activate once services pages should be shown
  // {
  //   name: RouteNames.ServicesRoot,
  //   path: "/services",
  //   redirect: "/services",
  //   component: RouterView,
  //   children: [
  //     {
  //       name: RouteNames.Services,
  //       path: "",
  //       component: ServicesPage,
  //     },
  //     {
  //       name: RouteNames.ServiceDetails,
  //       path: ":serviceId",
  //       component: ServiceDetailsPage,
  //     },
  //   ],
  // },
  {
    name: RouteNames.PrivacyPolicy,
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/public",
    redirect: "/public/logs",
    component: RouterView,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        name: RouteNames.PublicSiteLogs,
        path: "logs",
        component: PublicSiteLogsPage,
        props: (route) => ({
          ...route.query,
        }),
      },
    ],
  },
  {
    name: RouteNames.NotFoundAndError,
    path: "/:pathMatch(.*)*",
    component: NotFoundAndErrorPage,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(Config.BaseUrl),
  routes,
  scrollBehavior() {
    // Scroll to top
    return { top: 0, behavior: "auto" };
  },
});

router.beforeEach(async (to, from) => {
  Consoler.debugLog("Routing to", to);

  if (to.name === RouteNames.NotFoundAndError) {
    return;
  }

  EventBus.emit("RouterBeforeNavigationStarted");

  const authStore = useAuthStore();
  const requiresAuth = to.meta.requiresAuth !== false;

  if (requiresAuth && authStore.loggedIn === false) {
    EventBus.emit("RouterBeforeNavigationCompleted");
    window.location.href = Config.getLoginRoute(to.fullPath);
    return false;
  }

  if (requiresAuth) {
    const userProfileStore = useUserProfileStore();

    try {
      await userProfileStore.load();
    } catch (error) {
      if (error === ResponseErrorReason.Unauthorized) {
        // Is unauthorized ..
        if (from.name === undefined) {
          // .. but this is the first navigation (a route/view has not yet been shown), goto Login page
          Consoler.debugLog(
            "Unauthorized, but this is the first navigation (a route/view has not yet been shown), goto Login page"
          );
          EventBus.emit("RouterBeforeNavigationCompleted");

          window.location.href = Config.getLoginRoute(to.fullPath);
          return false;
        } else {
          // .. don't do anything, logged out modal should then show
          Consoler.debugLog(
            "Unauthorized, not continuing routing and letting logged out modal show"
          );
          EventBus.emit("RouterBeforeNavigationCompleted");
          return false;
        }
      } else {
        Consoler.debugWarn("Error loading user profile, going to error page");
        EventBus.emit("RouterBeforeNavigationCompleted");
        return {
          name: RouteNames.NotFoundAndError,
          params: {
            isError: true,
            returnToPath: to.fullPath,
          },
        } as any as RouteLocationRaw;
      }
    }

    // Set is logged in
    authStore.isLoggedIn();
  }

  EventBus.emit("RouterBeforeNavigationCompleted");
});

export default router;
