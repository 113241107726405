import { Language } from "@/models/Constants/Language";
import { createI18n } from "vue-i18n";

export const defaultLocale: string = "sv";
export const defaultLanguage: Language = Language.English;

import en from "./locales/en.json";
import sv from "./locales/sv.json";

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  messages: {
    en,
    sv,
  },
});

// Manually add the hot module replacement for the i18n module
if (import.meta.hot) {
  import.meta.hot.accept("./locales/en", function (en) {
    i18n.global.setLocaleMessage("en", en!.default);
  });
  import.meta.hot.accept("./locales/sv", function (sv) {
    i18n.global.setLocaleMessage("sv", sv!.default);
  });
}

export default i18n;
